/* .maindive {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../Assets/background.jpg') no-repeat center center;
    background-size: cover;
}

.wrapper {
    width: 90%;

    max-width: 420px;

    background: rgba(0, 0, 0, 0.2);

    border: 2px solid rgba(225, 225, 225, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 20px;
}

.wrapper h1 {
    font-size: 2rem;

    text-align: center;
    margin-bottom: 1rem;
}

.input-box {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

}

.input-box input {
    width: 100%;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;

    font-size: 1rem;

    color: white;
    padding: 10px 20px;
}

.input-box input::placeholder {
    color: white;
}

.input-box .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;

}

.remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;

    margin-bottom: 1rem;
}

.remember-forgot label input {
    accent-color: #fff;
    margin-right: 5px;
}

.remember-forgot a {
    color: #fff;
    text-decoration: none;
}

.remember-forgot a:hover {
    text-decoration: underline;
}

.wrapper button {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 1rem;
    color: #333;
    font-weight: 700;
    margin-bottom: 1rem;
}

.register-link {
    font-size: 0.875rem;

    text-align: center;
}

.register-link p a {
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.register-link p a:hover {
    text-decoration: underline;
}

.remember-forgot select option[value=""] {
    color: rgba(255, 255, 255, 0.7);
}

.remember-forgot select:invalid {
    color: rgba(255, 255, 255, 0.7);
}

.remember-forgot select:valid {
    color: white;
}


.remember-forgot select {
    width: 100%;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    font-size: 1rem;
    color: white;
    padding: 10px 20px;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10"><path fill="white" d="M1 1l6 6 6-6"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
}

.remember-forgot select:focus {
    border-color: rgba(255, 255, 255, 0.8);
    outline: none;
} */

/* ::-webkit-scrollbar {
    width: 8px;
  }
   
  ::-webkit-scrollbar-track {
    background-color: #aaa;
  } */
/*    
  ::-webkit-scrollbar-thumb {
    background-color: #335ddc;
    border-radius: 8px;
  } */

body {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}

.login-page {
    width: 100vw;
    height: 100vh;
    align-self: center;
    display: flex;
}

.login-box {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;

    overflow: hidden;
    margin: 0 auto;

}

#login-form {
    flex: 1 0 100%;
    max-width: 480px;
    width: 100%;
    padding: 60px;
}

#login-form p {
    margin-bottom: 30px;
}

#login-form p.form-title {
    color: #333333;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
}

#login-form p.form-title1 {
    color: #333333;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 15px;

    line-height: 1;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}


#login-form .ant-form-item-label>label.ant-form-item-required::before {
    display: none;
}

.ant-form-item-control-input-content {
    text-align: left;
}

#login-form .ant-input-affix-wrapper {
    padding: 15px 15px;
}

#login-form_username {
    height: 20px;
}

#login-form .ant-btn {
    height: 42px;
    letter-spacing: 1px;
    border-radius: 6px;
}

.login-form-button {
    width: 100%;
}

.illustration-wrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    background-color: #fffdf2;
}

.illustration-wrapper img {
    /* display: block; */
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1023px) {
    .login-box {
        flex-direction: column;
        box-shadow: none;
    }

    .illustration-wrapper {
        max-width: 100%;
        min-height: auto;
    }

    #login-form {
        max-width: 100%;
    }
}