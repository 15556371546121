
.mainContainer {
    display: flex;
    width: 100vw;
    height: 100vh; 
    overflow: hidden; 
}

.crow {
    display: flex;
    width: 100%;
    height: 100%;
}

.sub {
    width: 10%;
    height: 100vh; 
    position: fixed; 
    overflow: hidden; 
    transition: width 0.3s; 
}

.sub1 {
    margin-left: 200px;  /* Adjust this based on the sidebar width */
    width: 90%; 
    height: 100vh; 
    overflow-y: auto;
    box-sizing: border-box; 
}
