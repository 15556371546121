.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    z-index: 9999; /* Ensure it's above other content */
}

.loader-content {
    text-align: center;
}

.loader-text {
    margin-top: 20px; /* Space between animation and text */
    font-size: 1.2rem; /* Adjust font size as needed */
    color: #333; /* Adjust text color as needed */
}