/* .custom-modal {
    background-color: transparent;

    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    margin: auto;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.containerModal {
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.button {
    display: flex;
    justify-content: center;
    width: 100%;


}


.myButton2 {
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    background: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

}


.myButton2:hover {
    background-color: #2862d1;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;

}

.form-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;

}

.close-button {
    position: absolute;

    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
    font-weight: 700;
}

.form-title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.form-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.form-group {
    display: flex;
    flex-direction: column;
    width: calc(50% - 5px);

}

.form-group label {
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
}

.form-group input,
.form-group select {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}
