.mainDivlea1 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    /* Set the fixed height of the main view */
}

.mainDiv3 {
    display: flex;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

}



.detailContainer1 {
    width: 100%;
    height: 100%;

    border-radius: 5px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

}





.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196f3;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.card-container1 {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* height: 00px;  */
    display: flex;
    flex-direction: column;
}

.table-container1 {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: auto;
}


.employee-table1 {
    width: 100%;
    border-collapse: collapse;
}

.employee-table1 th,
.employee-table1 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}

.employee-table1 th {
    min-width: 100px;
    /* You can adjust this value */
    background-color: #f4f4f4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}