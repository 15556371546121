
.mainDiv {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}


.crow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.crow1 {
    display: flex;
    align-items: center;
}


.button-container {
    display: flex;
    justify-content: center;
}


.myButton {
    background-color: #3378ff;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
}


.myButton:hover {
    background-color: #2862d1;
}

@media (max-width: 768px) {
    .mainDiv {
        padding: 15px;
    }

    .myButton {
        padding: 12px 24px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .myButton {
        padding: 10px 20px;
        font-size: 12px;
    }
}


.searchBar-container {
    width: 100%;
    border: 1px solid #ddd;
    margin-top: 30px;
    border-radius: 8px;
    background-color: #fff;
}

.searchBar-container1 {
    display: flex;
    align-items: center;
    padding: 50px;
}

.searchBar-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.searchBar-button {
    padding: 10px 20px;
    border: none;
    background-color: #3378ff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    margin-left: 10px;
}

.searchBar-button:hover {
    background-color: #2862d1;
}


.card-container {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* height: 00px;  */
    display: flex;
    flex-direction: column; 
}

.table-container {
    flex-grow: 1; 
    overflow-x: auto; 
    overflow-y: auto;
}


.employee-table {
    width: 100%;
    border-collapse: collapse;
}

.employee-table th, .employee-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
    
}

.employee-table th {
    min-width: 100px; /* You can adjust this value */
    background-color: #f4f4f4;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
}


.action-button {
    background: none;
    border: none;
    color: #3378ff;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.action-button:hover {
    color: #2862d1;
}


@media (max-width: 768px) {
    .employee-table th, .employee-table td {
        padding: 6px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .employee-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .employee-table th, .employee-table td {
        display: inline-block;
        width: 120px;
    }
}
