.mainDivleave1 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    /* Set the fixed height of the main view */
}

.detailContainer1 {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

}

.form-group2 {
    display: flex;
    /* flex-direction: column; */
    /* width: calc(50% - 5px); */
    margin-left: 50px;
    border-width: 1px;
    border-color: gray;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    padding-top:5px;
    align-items: center;
}