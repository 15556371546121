/* Form Layout */
.form-grid1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group1 {
    display: flex;
    align-items: center;
    /* Align label and input vertically */
    gap: 10px;
    /* Add space between label and input */
    width: 100%;
}

.form-group1 label {
    font-size: 18px;
    font-weight: 500;
    color: black;
    min-width: 150px;
    /* Set a minimum width for the label to control spacing */
}

.form-group1 input,
.form-group1 select {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    flex: 1;
    /* Make input take up the remaining space */
}
.form-group1 textarea {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    resize: vertical; /* Allows vertical resizing by the user */
    min-height: 100px; /* Minimum height to start with, adjust as needed */
}
.radio-group {
    display: flex;
    align-items: center;

    margin-left: -30px;
    /* Add space between radio buttons and labels */
}

.radio-group label {
    font-size: 18px;

    margin-left: -10px;
    display: flex;
}

.radio-group input {
    margin-left: 5px;
}

/* Button Styles */
.button1 {
    display: flex;
    justify-content: center;
    width: 100%;
}

.myButton2 {
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    background: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.myButton2:hover {
    background-color: #2862d1;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}
.validity-group {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between validity label and date inputs */
}

.date-group {
    display: flex;
    gap: 20px; /* Space between start and end date fields */
}

.date-field {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between label and input */
}
