@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  body {
    background: #f1f1f1;
    font-family: "Tajawal", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  .link {
    @apply p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 font-medium;
  }

  #root {
    display: flex;
    flex: 1;
  }

  h1 {
    @apply text-xl font-medium capitalize;
  }
}

.active {
  @apply bg-blue-100 text-blue-600;
}

.dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

}

.dialog {
  background-color: white;
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;

}

.dialog-buttons {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.dialog-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}

.confirm-btn {
  background-color: #007bff;
  color: white;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

}

.cancel-btn {
  background-color: #6c757d;
  color: white;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

}

.e-dlg-overlay {
  width: 100%;
  padding: 10px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: slategray;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  color: white;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
