.mainDivleave {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    /* Set the fixed height of the main view */
}

.mainDiv2 {
    display: flex;
    flex-direction: row;
    flex: 1;
    /* Ensure it takes up available space */
    box-sizing: border-box;
    overflow: hidden;
    /* Hide overflow from mainDiv2 */
}

.mainDiv3 {
    display: flex;
    background-color: #007bff;

    flex: 1;
    /* Ensure it takes up available space */
    box-sizing: border-box;
    overflow: hidden;
    /* Hide overflow from mainDiv2 */
}


.detailContainer {
    width: 80%;
    height: 100%;
    /* Ensure it takes up full height of mainDiv2 */
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* Enable vertical scroll */
}

.detailContainer2 {
    width: 20%;
    margin-left: 10px;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    overflow-y: auto;
    /* Enable vertical scroll */
}


.searchBar-2 {
    width: 100%;

    padding: 5px 0;
}

.searchBar-input {


    width: 100%;
    padding: 5px 35px 5px 5px;
    /* Adjust padding to make space for the icon */
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.searchBar-wrapper {
    position: relative;

    width: 100%;
}

.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    color: #888;
    pointer-events: none;
}

.employee-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, border-bottom 0.3s;

}

.employee-item:hover {
    background-color: #f0f0f0;

}

.employee-item.selected {
    background-color: #d7eaff;
    border-bottom: 2px solid #007bff;
    color: black;

}

.user-icon {
    margin-right: 10px;
    color: #007bff;

}

.employee-name {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;

}

.employee-name1 {
    font-size: 13px;
    font-weight: 400;

}

.searchBar-button1 {
    padding: 5px 12px;
    border: none;
    background-color: #3378ff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    margin-left: 5px;
}

.employee-table1 {
    width: 100%;
    border-collapse: collapse;
}

.employee-table1 th,
.employee-table1 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.employee-table1 th {
    background-color: #f4f4f4;
}

.action-button {
    background: none;
    border: none;
    color: #3378ff;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.action-button:hover {
    color: red;
}

.switch-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px;

}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196f3;
}

input:checked+.slider:before {
    transform: translateX(26px);
}
.card-container1 {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* height: 00px;  */
    display: flex;
    flex-direction: column; 
}

.table-container1 {
    flex-grow: 1; 
    overflow-x: auto; 
    overflow-y: auto;
}


.employee-table1 {
    width: 100%;
    border-collapse: collapse;
}

.employee-table1 th, .employee-table1 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
    
}

.employee-table1 th {
    min-width: 100px; /* You can adjust this value */
    background-color: #f4f4f4;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
}
